var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { checkSheetsModule } from '@cognitranlimited/itis-web/dist/check-sheets';
import { campaignsModule } from '@cognitranlimited/itis-web/dist/campaigns';
import { SidebarLogo } from './SidebarLogo';
import { MobileSidebarLogo } from './MobileSidebarLogo';
import { dropdownLinksModule } from './amtisLinks/dropdownLinksModule';
import { leftMenuLinksModule } from "./amtisLinks/leftMenuLinksModule";
import { topMenuLinksModule } from "./amtisLinks/topMenuLinks/topMenuLinksModule";
import { joinPath } from "./paths";
import { getProperty, requireGlobalProperty } from './utils';
import { dealerModule } from "./dealer/dealerModule";
import { vehicleInformationModule } from './vehicleInformationModule/vehicleInformationModule';
function getModules() {
    return [
        checkSheetsModule(),
        campaignsModule({ showFilters: true }),
        dropdownLinksModule(),
        leftMenuLinksModule(),
        topMenuLinksModule(),
        dealerModule(),
        vehicleInformationModule()
    ];
}
export var getProps = function () { return ({
    apiUrl: requireGlobalProperty('apiUrl'),
    itisUrl: requireGlobalProperty('itisUrl'),
    authClientId: requireGlobalProperty('authClientId'),
    authSecret: requireGlobalProperty('authSecret'),
    authorisedDealerAccessUrl: requireGlobalProperty('authorisedDealerAccessUrl'),
    brand: 'iwaCore',
    vehiclePlateRecognitionEndpoint: joinPath(requireGlobalProperty('vprUrl'), 'recognize'),
    stringsLoader: function (languageCode) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ "./strings/".concat(languageCode, ".strings"))];
                case 1: return [2 /*return*/, (_a.sent()).default];
            }
        });
    }); },
    helpStringsLoader: function (languageCode) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import(/* webpackChunkName: '[request]' */ "./strings/".concat(languageCode, "Help.strings"))];
                case 1: return [2 /*return*/, (_a.sent()).default];
            }
        });
    }); },
    vehiclePanelOrder: ['documentGroupLookup', 'notices', 'importantDocuments', 'favouriteContent'],
    hideModelSelectionByDefault: true,
    hideSearchBarWhenUserLoggedOut: true,
    sidebarLogo: function () { return React.createElement(SidebarLogo, null); },
    mobileSidebarLogo: function () { return React.createElement(MobileSidebarLogo, null); },
    modules: getModules(),
    headerElementsOrder: {
        search: 0,
        account: 1,
        help: 2,
        modules: 3
    },
    defaultSearchOptions: {
        searchTitles: true,
        searchDescriptions: true,
        searchContents: false
    },
    showItisVersion: true,
    itisContactPage: function () { return '/contact'; },
    openAdminPageInNewTab: false,
    openAuthoringPageInNewTab: false,
    allowExactVehicleSelectionForSerialLookups: true,
    showNonVehicleSidebarDocumentGroups: true,
    documentViewEventUrl: process.env.NODE_ENV === 'production' ? getProperty('documentEventUrl') : undefined
}); };
