import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./Snackbar.scss";
export var Snackbar = function (_a) {
    var open = _a.open, message = _a.message, _b = _a.duration, duration = _b === void 0 ? 3000 : _b, variant = _a.variant, onClose = _a.onClose;
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var classes = classNames("Snackbar", {
        "Snackbar-hidden": !isOpen,
        "Snackbar-mobile": variant === 'mobile'
    });
    useEffect(function () {
        setIsOpen(open);
        setTimeout(function () {
            setIsOpen(false);
            onClose && onClose();
        }, duration);
    }, [open]);
    return (React.createElement("div", { className: classes },
        React.createElement("span", { dangerouslySetInnerHTML: { __html: message } })));
};
